import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Questions from '../views/Questions.vue'
import ThankYou from '../views/ThankYou.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/domande',
    name: 'Questions',
    component: Questions
  },
  {
    path: '/grazie',
    name: 'ThankYou',
    component: ThankYou
  }
]

const router = new VueRouter({
    mode: 'history',
    //base: '/',
    routes
  })

export default router
