<template>
  <div id="thnakyou">
    <div class="pb-5">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 col-11 p-5 bg-white rounded shadow main-container mb-5">

          <h1 class="text-center text-uppercase mb-1">Grazie</h1>
          <h5 class="text-center text-uppercase mb-5">per aver compilato l’assessment AGILE INDEX</h5>
          <p class="text-center small">A breve riceverà il report contenente la valutazione dello stato attuale della sua Organizzazione.<br>
          <strong>Per qualsiasi chiarimento, ci scriva a <a href="mailto:info@agile-group.it">info@agile-group.it</a></strong></p>

          <p class="text-center small mt-5">Stai per essere reindirizzato al nostro sito web tra {{ countDown }} secondi...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      countDown: 10
    }
  },
  computed: {
    session() {
      return this.$store.getters.session;
    }
  },
  watch: {
    countDown(value) {
      if (value == 0) {
        window.location.href = "https://agile-group.it/solutions/";
      }
    }
  },
  methods: {
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
        }, 1000)
      }
    }
  },
  created() {
    this.countDownTimer()
    document.title = "Grazie per aver compilato il modulo | Agile Group";
    if (!this.$cookie.get('token')) {
      this.$router.push({name: 'Home'})
    }
    this.$cookie.delete('token');
  }
}
</script>
