<template>
  <div id="home">
    <div class="pb-5">
      <div class="row justify-content-center">
        <div class="col-xl-7 col-lg-9 col-11 p-5 bg-white rounded shadow main-container mb-5" data-aos="fade-up" data-aos-delay="300">
          <h1 class="mb-4 text-center text-uppercase">Agile Index Test</h1>

          <p class="small">
            Analisi basata sulla metodologia della <strong>NeuroAgilità Organizzativa®</strong>, a tua disposizione per aiutarti a identificare i punti di forza che fanno brillare il tuo team e le potenziali barriere che potrebbero ostacolarne il rendimento.
          </p>
          <p class="small">
            Che tu sia un team leader o un componente del team, riceverai una valutazione dettagliata che ti permetterà di capire meglio il tuo team e come massimizzarne il potenziale.
          </p>
          <p class="small">
            Con preziosi suggerimenti su come sviluppare al massimo le capacità della tua squadra, il test <strong>AGILE INDEX</strong> ti guiderà nel trasformare un gruppo di lavoro nel "<strong>Cerchio Magico</strong>" della tua azienda.
          </p>
          <p class="small">
            Se sei pronto a superare ogni sfida e a raggiungere risultati straordinari, inizia il tuo viaggio verso il successo con il test <strong>AGILE INDEX</strong>.
          </p>

          <div class="bg-secondary mb-4 mt-5 p-4 rounded">
            <h4 class="text-uppercase text-center text-center mb-4">Istruzioni operative</h4>

            <div class="row">
              <div class="col-md-6 mb-4 small fw-400 color-primary">
                <p>La compilazione del questionario richiede pochi minuti e va fatta in un momento riservato in modo da potersi calare nello spirito corretto.<br>
                Occorre rispondere in modo coerente con come si vive l'azienda e non come si vorrebbe viverla; la sincerità e la coerenza delle risposte consentono un risultato di grande utilità per lo sviluppo aziendale.</p>
                <p>Le domande sono tutte obbligatorie e sono del tipo a scala lineare a cinque punti.<br>
                Per ogni domanda, occorre semplicemente rispondere scegliendo tra le cinque opzioni, considerando il livello di rispondenza:</p>
              </div>
              <div class="col-md-6 mb-4 small fw-400 color-primary">
                <p class="mb-2"><strong class="lead">1.</strong> Significa "PER NIENTE, corrisponde per niente alla realtà aziendale"</p>
                <p class="mb-2"><strong class="lead">2.</strong> Significa "POCO, corrisponde poco alla realtà aziendale"</p>
                <p class="mb-2"><strong class="lead">3.</strong> Significa "SUFFICIENTE, corrisponde in modo sufficiente alla realtà aziendale"</p>
                <p class="mb-2"><strong class="lead">4.</strong> Significa "ABBASTANZA, corrisponde abbastanza bene alla realtà aziendale"</p>
                <p class="mb-2"><strong class="lead">5.</strong> Significa “TOTALMENTE, corrisponde totalmente alla realtà aziendale"</p>
                <p>Alla fine del questionario è sufficiente inviarlo, in pochi giorni riceverai all'indirizzo email il tuo Report AGILE INDEX.</p>
              </div>
            </div>
          </div>

          <p class="extra-small text-center">
            <strong class="color-primary text-uppercase">
              Esonero di responsabilità:
            </strong>
            Con la scelta di effettuare il test esoneri AGILE GROUP Srl, le persone e le aziende a lei correlate o affiliate, da qualsiasi responsabilità e qualsiasi azione o causa legale di qualsiasi tipo, natura e descrizione derivante o connessa alla tua esecuzione del test, in relazione all'utilizzo del risultato del test da parte dell’azienda. AGILE GROUP Srl non è responsabile di eventuali decisioni prese dall'azienda che ha richiesto di completare il test e dalla tua persona che ha scelto di completare il test. Le informazioni contenute nel report non devono essere utilizzate come unico elemento su cui basare le decisioni aziendali. Indicando la tua e-mail confermi che hai letto e compreso questo accordo.
          </p>

          <div class="row justify-content-center mb-4" data-aos="fade-up">
            <div class="col-lg-8 col-md-10">
              <hr class="my-5">
              <h4 class="text-uppercase text-center text-center mb-5">Inserisci i tuoi dati</h4>

              <b-form @submit="onSubmit">
                <b-form-group id="input-group-name" label="Nome completo" label-for="input-name">
                  <b-form-input
                    id="input-name"
                    v-model="session.name"
                    placeholder="Inserisci il nome completo"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-email" label="Email" label-for="input-email">
                  <b-form-input
                    id="input-email"
                    v-model="session.email"
                    placeholder="Inserisci la tua email"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-company" label="Nome azienda (campo facoltativo)" label-for="input-company">
                  <b-form-input
                    id="input-company"
                    v-model="session.company"
                    placeholder="Inserisci il nome dell'azienda"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-phone_number" label="Numero di telefono (campo facoltativo)" label-for="input-phone_number">
                  <b-form-input
                    id="input-phone_number"
                    v-model="session.phone_number"
                    placeholder="Inserisci il tuo numero di telefono"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-role" label="Ruolo (campo facoltativo)" label-for="input-role">
                  <b-form-input
                    id="input-role"
                    v-model="session.role"
                    placeholder="Inserisci il tuo ruolo nell'azienda"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3" label="Settore (campo facoltativo)" label-for="input-3">
                  <b-form-select
                    id="input-3"
                    v-model="session.sector"
                    :options="sectors"
                  >
                    <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleziona il settore dell'azienda--</b-form-select-option>
                  </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group id="input-group-3" label="Numero dipendenti (campo facoltativo)" label-for="input-3">
                  <b-form-select
                    id="input-3"
                    v-model="session.employees_number"
                    :options="employees_choices"
                  >
                    <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleziona il numero di dipendenti--</b-form-select-option>
                  </template>
                  </b-form-select>
                </b-form-group>

                <b-form-group>
                  <b-form-checkbox class="small color-default" v-model="acceptPrivacy">
                    Accetto le condizioni della privacy limitatamente allo scopo del presente test
                  </b-form-checkbox>
                </b-form-group>

                <b-button class="btn-lg btn-block mt-4 mb-4" type="submit" variant="primary" :disabled="!acceptPrivacy">Continua</b-button>
              </b-form>
            </div>
          </div>

          <p class="extra-small color-primary">
            <strong class="text-uppercase">Dati personali e politica della privacy</strong><br>
            In questa sezione chiediamo di lasciarci il tuo nominativo, il nome della tua azienda, il settore d'attività in cui opera, il numero dei dipendenti e il tuo ruolo in azienda.
          </p>

          <p class="extra-small color-primary">
            <strong>Informazioni utente</strong><br>
            I dati personali saranno utilizzati per personalizzare il test e per le finalità dello stesso.
          </p>

          <p class="extra-small color-primary">
            <strong class="text-uppercase">Consenso all'utilizzo dei dati</strong><br>
            Selezionando la casella sottostante, presti il tuo consenso a AGILE GROUP Srl a trattare i dati personali summenzionati e il tuo indirizzo IP, in relazione al completamento del test. Completando il test, accetti espressamente che AGILE GROUP Srl possa valutare i tuoi dati personali e utilizzare i risultati per creare diversi report, incluso un report relativo alla tua azienda, che saranno rilasciati solamente all'azienda o al collaboratore di AGILE GROUP Srl che, a titolo di consulente di AGILE GROUP Srl, gestisce le relazioni con l’azienda. Qualora successivamente decidessi di non volere che i tuoi dati personali vengano utilizzati, contatta AGILE GROUP Srl tramite il seguente indirizzo e-mail: info@agile-group.it, e i tuoi dati personali saranno cancellati entro un periodo di 15 giorni. Hai il diritto di ritirare il tuo consenso al trattamento dei tuoi dati personali in qualsiasi momento e puoi farlo scrivendo al suddetto indirizzo e-mail.
          </p>

          <p class="extra-small color-primary">
            Per confermare la comprensione e l'accettazione delle condizioni di privacy spunta la casella sottostante. La tua privacy è importante per noi e siamo costantemente impegnati a rispettare i requisiti rigorosi del sistema di Privacy Policy di AGILE GROUP Srl, consultabile al seguente link: <a href="https://agile-group.it/privacy-policy/" target="_blank">https://agile-group.it/privacy-policy/</a> *
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiService } from '../common/api.service'
import { constants } from '@/common/constants.js';
export default {
  data() {
    return {
      session: {
        name: '',
        company: '',
        sector: null,
        employees_number: null,
        phone_number: '',
        email: '',
        role: ''
      },
      sectors: [],
      employees_choices: constants.session.employees_choices.toArray(),
      acceptPrivacy: false
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      let parameters = {};
      if ("consulente" in this.$route.query) {
        parameters.consulant = this.$route.query.consulente
      }

      let endpoint = '/api/sessions/';

      apiService(endpoint, 'POST', this.session, parameters).then((data) => {
        if (data.error_code) {
          this.$store.commit('handleErrors', data);
        } else {
          this.$store.commit("setSession", data);
          this.$cookie.set('token', data.token);
          this.$router.push({ name: 'Questions'}).catch(()=>{});
        }
      });
    },
    getSectors: function () {
      let endpoint = '/api/sectors/';

      apiService(endpoint).then((data) => {
        if (data.error_code) {
          this.$store.commit('handleErrors', data);
        } else {
          this.sectors = [];

          let vm = this;
          data.forEach(function (type) {
            vm.sectors.push({
              value: type.id,
              text: type.text
            });
          });
        }
      });
    },
  },
  created() {
    document.title = "Compila dati | Agile Group";
    if (this.$cookie.get('token')) {
      this.$router.push({name: 'Questions'})
    }
    else {
      this.getSectors();
    }
  },
}
</script>
