const constants = {
  session: {
    employees_choices: {
      LESS_THAN_15: {
        value: 0,
        text: 'Meno di 15'
      },
      BETWEEN_15_100: {
        value: 1,
        text: 'Tra 15 e 100'
      },
      BETWEEN_100_500: {
        value: 2,
        text: 'Tra 100 e 500'
      },
      MORE_THAN_500: {
        value: 3,
        text: 'Maggiore di 500'
      },
      toArray: function () {
        var employeesChoicees = [];
        Object.entries(constants.session.employees_choices).map(item => {
          var employees_choice = item[1];

          if (typeof employees_choice != 'function') {
            employeesChoicees.push({
              value: employees_choice.value,
              text: employees_choice.text
            });
          }
        })

        return employeesChoicees;
      }
    }
  }
}

export { constants };
