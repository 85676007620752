import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: {},
    errors: null,
  },
  getters: {
    session(state) {
      return state.session
    },
    errors(state) {
      return state.errors;
    },
  },
  mutations: {
    setSession(state, payload) {
      state.session = payload;
    },
    handleErrors(state, payload) {
      state.errors = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
