<template>
  <div id="questions">
    <aside id="aside-info-box" class="position-fixed" style="left:0; right:0; bottom:15px; top:auto; z-index:999; transition:all 0.5s ease">
      <section>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-7 col col-10 p-4 bg-secondary rounded shadow color-primary" id ="info-box">
              <img src="static/imgs/logo.svg" class="mb-3 d-none d-sm-inline-block" style="max-width:80px;">
              <p class="mb-0 extra-small"><strong>1. PER NIENTE</strong><span class="d-none d-sm-inline">, corrisponde per niente alla realtà aziendale</span><br>
              <strong>2. POCO</strong><span class="d-none d-sm-inline">, corrisponde poco alla realtà aziendale</span><br>
              <strong>3. SUFFICIENTE</strong><span class="d-none d-sm-inline">, corrisponde in modo sufficiente alla realtà aziendale</span><br>
              <strong>4. ABBASTANZA</strong><span class="d-none d-sm-inline">, corrisponde abbastanza bene alla realtà aziendale</span><br>
              <strong>5. TOTALMENTE</strong><span class="d-none d-sm-inline">, corrisponde totalmente alla realtà aziendale</span></p>
            </div>
          </div>
        </div>
      </section>
    </aside>

    <div class="row justify-content-center" v-if="$cookie.get('token')">
        <div class="col-xl-7 col-lg-9 col-11 py-5 px-1 px-md-3 px-lg-5 bg-white rounded shadow main-container mb-5 aos-init aos-animate">
          <h1 class="mb-4 text-center text-uppercase">Agile Index Test</h1>

          <div class="container my-5">
            <div v-for="(question, index) in questions" v-bind:key="index" :ref="'question-' + question.id" class="question-wrapper question-wrapper-primary p-2" data-aos="fade-up">
              <div
                class="mb-5 rounded border py-3 px-4 bg-white"
                data-aos="fade-up">

                <h2 class="mb-0 h1" v-bind:class="titleClass(question.id, index)">{{ index + 1 }}.</h2>
                <p v-bind:class="paragraphClass(question.id)"><strong>{{ question.text }}</strong></p>

                <b-form-group>
                  <b-form-radio-group
                    :id="'radio-group-' + index"
                    :name="'radio-options' + index"
                    :key="index"
                    v-model="question.answer_value"
                    @change="saveAnswer(question)"
                  >
                    <b-form-radio :value="n" v-for="n in 5" :key="n">{{n}}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
            <b-button v-if="questions.length > 0" id="btn-submit" @click="compileModule" class="btn btn-primary btn-lg btn-block mt-4 mb-5" data-aos="fade">Invia</b-button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { apiService } from '../common/api.service';
export default {
  data() {
    return {
      questions: [],
      newAnswer: null,
      missingQuestions: [],
      showInfoBox: true
    }
  },
  computed: {
    session() {
      return this.$store.getters.session;
    }
  },
  methods: {
    getQuestions: function () {
      let endpoint = '/api/questions/';

      apiService(endpoint, 'GET', undefined, {token: this.$cookie.get('token')}).then((data) => {
        if (data.error_code) {
          this.$store.commit('handleErrors', data);
        } else {
          this.questions = data;
        }
      });
    },
    saveAnswer(question) {
      let endpoint = '/api/answers/';
      let method = 'POST';
      let data = {question_id: question.id, value: question.answer_value};

      this.missingQuestions = this.missingQuestions.filter(function(missingQuestion) {
        return missingQuestion !== question.id;
      });

      if (question.answer_id) {
        endpoint = '/api/answers/' + question.answer_id + '/';
        method = 'PATCH';
        data = {value: question.answer_value}
      }

      apiService(endpoint, method, data, {token: this.$cookie.get('token')}).then((data) => {
        if (data.error_code) {
          this.$store.commit('handleErrors', data);
        } else {
          var index = this.questions.findIndex(question => question.id === data.question_id);
          if (index !== -1) {
            this.questions[index].answer_id = data.id;
            this.questions[index].answer_value = data.value;
          }
        }
      });
    },
    compileModule() {
      let endpoint = '/api/answered/';

      apiService(endpoint, 'GET', undefined, {token: this.$cookie.get('token')}).then((data) => {
        console.log(data);
        if (data.error_code) {
          this.missingQuestions = data.questions;

          this.goto('question-' + data.questions[0]);
        } else {
          this.$router.push({name: 'ThankYou'})
        }
      });
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element[0].offsetTop;

      window.scrollTo(0, top);
    },
    titleClass(question_id, index) {
      var classes = "";

      if (index % 2 == 0) {
        classes = "color-primary";
      } else {
        classes = "color-secondary";
      }

      if (this.missingQuestions.includes(question_id)) {
        classes = " text-danger";
      }

      return classes;
    },
    paragraphClass(question_id) {
      var classes = "color-primary";

      if (this.missingQuestions.includes(question_id)) {
        classes = " text-danger";
      }

      return classes;
    },
    scrollHandler() {
      var btnEl = document.getElementById("btn-submit");
      if (btnEl) {
        if (!this.isScrolledIntoView(btnEl)) {
          document.getElementById("aside-info-box").style.transform = 'translateY(0)';
        }
        else {
          document.getElementById("aside-info-box").style.transform = 'translateY(200%)';
        }
      }
    },
    isScrolledIntoView(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;

        // Only completely visible elements return true:
        var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;
    }
  },
  created() {
    document.title = "Rispondi alle domande | Agile Group";
    if (!this.$cookie.get('token')) {
      this.$router.push({name: 'Home'})
    }
    else {
      this.getQuestions();
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>
