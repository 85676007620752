import { CSRF_TOKEN } from "./csrf_token.js";

function handleResponse(response) {
  if (response.status === 204) {
    return "";
  } else {
    return response.json();
  }
}

function apiService(endpoint, method, data, parameters) {
  // D.R.Y. code to make HTTP requests to the REST API backend using fetch
  const config = {
    method: method || "GET",
    body: data !== undefined ? JSON.stringify(data) : null,
    headers: {
      "content-type": "application/json",
      "X-CSRFTOKEN": CSRF_TOKEN
    }
  };

  // https://stackoverflow.com/questions/316781/how-to-build-query-string-with-javascript/49701878#49701878
  if (parameters !== undefined) {
    const params = new URLSearchParams(parameters);
    endpoint += "?" + params.toString();
  }

  return fetch(endpoint, config)
    .then(handleResponse)
    .catch(error => alert(error));
}

export { apiService };
