<template>
  <b-modal :id="id" :title="title" title-class="modal-title-error" hide-footer>
    <p v-for="(error, index) in errors" v-bind:key="index">
      {{ index | capitalizeFirstLetter }}:
      <template v-if="isArray(error)">{{ error[0] }}</template>
      <template v-else>{{ error }}</template>
    </p>
  </b-modal>
</template>

<style>
.modal-title-error {
  color: #dc3545;
}
</style>

<script>
export default {
  name: "ErrorModal",
  data() {
    return {
      id: Math.random()
        .toString(36)
        .substring(7)
    };
  },
  props: {
    title: {
      type: String,
      required: true
    },
    errors: {
      required: true
    }
  },
  methods: {
    isArray: function(item) {
      return Array.isArray(item);
    },
    showModal: function() {
      this.$bvModal.show(this.id);
    }
  },
  filters: {
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
};
</script>
